import { Reducer } from 'redux';

import api from '../../../services/api';
import EnumPerfil from '../../../types/enum/Perfis';
import EnumResponsabilidade from '../../../types/enum/Responsabilidade';
import TipoAcesso from '../../../types/TipoAcesso';

import { EnumAuthActions } from './actions';

export interface IAuthState {
  authenticated: boolean;
  token?: string;
  responsabilidade?: EnumResponsabilidade;
  tipo?: TipoAcesso;
  perfis?: EnumPerfil;
  show_currency_values: boolean;
  menu_opened: boolean;
  permissoes: {
    id_permissao_perfil: number;
    nome: string;
  }[];
}

const initial_state: IAuthState = {
  authenticated: false,
  token: undefined,
  responsabilidade: undefined,
  tipo: undefined,
  perfis: undefined,
  show_currency_values: false,
  menu_opened: false,
  permissoes: [],
};

const auth: Reducer<IAuthState> = (state = initial_state, action) => {
  switch (action.type) {
    case EnumAuthActions.SIGN_IN:
      const { payload } = action;

      api.defaults.headers.authorization = `Bearer ${payload.token}`;

      return {
        ...state,
        authenticated: payload.authenticated,
        token: payload.token,
        tipo: payload.tipo,
        permissoes: payload.permissoes,
      };

    case EnumAuthActions.SIGN_OUT:
      api.defaults.headers.authorization = ``;

      return initial_state;

    case EnumAuthActions.UPDATE_RESPONSABILIDADE:
      return {
        ...state,
        responsabilidade: action.payload.responsabilidade,
      };

    case EnumAuthActions.UPDATE_SHOW_CURRENCY_VALUE:
      return {
        ...state,
        show_currency_values: action.payload.show_currency_values,
      };

    case EnumAuthActions.TOGGLE_MENU:
      return {
        ...state,
        menu_opened: action.payload.toggle_menu || !state.menu_opened,
      };

    default:
      return state;
  }
};

export default auth;
